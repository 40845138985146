/* eslint-disable new-cap */
import { MetaReducer as SourceMetaReducer } from 'SourceStore/Meta/Meta.reducer';
import {
    MetaActionType,
} from 'Store/Meta/Meta.type';

/** @namespace Theme/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (
    state,
    action,
) => {
    const { type, payload } = action;

    switch (type) {
    case MetaActionType.UPDATE_META:
        const { canonical_url } = payload;

        if (!canonical_url) {
            document.querySelector('link[rel="canonical"]')?.remove();
            break;
        }

        if (document.querySelector('link[rel="canonical"]')) {
            document.querySelector('link[rel="canonical"]').href = canonical_url;
        } else {
            const linkTag = document.createElement('link');
            linkTag.setAttribute('rel', 'canonical');
            linkTag.href = canonical_url;
            document.head.appendChild(linkTag);
        }
        break;
    default:
        break;
    }

    return SourceMetaReducer(state, action);
};

export default MetaReducer;
