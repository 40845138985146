import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer,
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Theme/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    getIsLoading() {
        const { requestedUrl } = this.props;

        const target = location.pathname + (location.pathname.endsWith('.asp') ? location.search : '');

        return target !== requestedUrl;
    }

    requestUrlRewrite() {
        const { requestUrlRewrite } = this.props;

        const target = location.pathname + (location.pathname.endsWith('.asp') ? location.search : '');

        return requestUrlRewrite(target);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
