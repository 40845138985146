/* eslint-disable no-unused-vars */
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

import { CartActionType } from './Cart.type';

export const CART_TOTALS = 'cart_totals';

/** @namespace Theme/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action, state) => {
    const { cartData: { items = [], shipping_addresses = {}, ...rest } = {} } = action;

    const cartTotals = {
        ...rest,
        items: [],
        shipping_addresses: {},
    };

    if (items.length) {
        const normalizedItemsProduct = items.map((item) => {
            const {
                variants,
                bundle_customizable_options,
                configurable_customizable_options,
                downloadable_customizable_options,
                virtual_customizable_options,
                simple_customizable_options,
                ...normalizedItem
            } = item;

            normalizedItem.product = getIndexedProduct(item.product, item.sku);

            normalizedItem.customizable_options = bundle_customizable_options
                || configurable_customizable_options
                || downloadable_customizable_options
                || virtual_customizable_options
                || simple_customizable_options
                || [];

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
    }

    cartTotals.shipping_addresses = shipping_addresses[0] || {};
    cartTotals.no_shipping = cartTotals.is_virtual;
    cartTotals.is_virtual = false;

    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS,
    );

    return {
        cartTotals,
        isLoading: false,
        isAddingToCart: state.isAddingToCart,
    };
};

/** @namespace Theme/Store/Cart/Reducer/updateShippingPrice */
export const updateShippingPrice = (action, state) => {
    const {
        data: {
            discount_amount,
            grand_total,
            shipping_amount,
            shipping_incl_tax,
            shipping_tax_amount,
            subtotal,
            subtotal_incl_tax,
            subtotal_with_discount,
            tax_amount,
        } = {},
    } = action;

    const shipping = {
        prices: {
            ...state.cartTotals.prices,
            applied_taxes: [
                {
                    ...state.cartTotals.prices?.applied_taxes[0],
                    amount: {
                        value: tax_amount,
                    },
                },
            ],
            discount: {
                ...state.cartTotals.prices?.discount,
                amount: {
                    value: discount_amount,
                },
            },
            grand_total: {
                value: grand_total,
            },
            subtotal_excluding_tax: {
                value: subtotal,
            },
            subtotal_including_tax: {
                value: subtotal_incl_tax,
            },
            subtotal_with_discount_excluding_tax: {
                value: subtotal_with_discount,
            },
        },
        shipping_addresses: {
            ...state.cartTotals.shipping_addresses,
            selected_shipping_method: {
                amount: {
                    value: shipping_amount,
                },
                amount_incl_tax: shipping_incl_tax,
                tax_amount: shipping_tax_amount,
            },
        },
    };

    return {
        isLoading: state.isLoading,
        isAddingToCart: state.isAddingToCart,
        cartTotals: {
            ...state.cartTotals,
            ...shipping,
        },
    };
};

/** @namespace Theme/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    isAddingToCart: false,
    isLoading: false,
    cartTotals: BrowserDatabase.getItem(CART_TOTALS) || {},
});

/** @namespace Theme/Store/Cart/Reducer/CartReducer */
export const CartReducer = (
    state = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {
    case CartActionType.UPDATE_TOTALS:
        return updateCartTotals(action, state);
    case CartActionType.UPDATE_SHIPPING_PRICE:
        return updateShippingPrice(action, state);
    case CartActionType.UPDATE_IS_LOADING_CART:
        const { isLoading } = action;

        return {
            ...state,
            isLoading,
        };
    case 'UPDATE_IS_ADDING_TO_CART':
        const { isAddingToCart } = action;

        return {
            ...state,
            isAddingToCart,
        };
    default:
        return state;
    }
};

export default CartReducer;
