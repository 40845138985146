/* eslint-disable no-magic-numbers */
/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-let */

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer,
} from 'SourceComponent/Router/Router.container';
import history from 'Util/History';
import sso from 'Util/SSO';

/** @namespace Theme/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    canonical_url: state.MetaReducer.canonical_url,
});

/** @namespace Theme/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = sourceMapDispatchToProps;

/** @namespace Theme/Component/Router/Container/trackVirtualPage */
export const trackVirtualPage = (pageName) => {
    if (!pageName) {
        pageName = window.document.title;
    }

    try {
        if (typeof window.wap_tms?.custom?.trackGaVirtualPageView === 'function') {
            window.wap_tms.custom.trackGaVirtualPageView(pageName);

            return;
        }

        const tries = 30;
        const time = 250;
        let executions = 1;

        const interval = setInterval(() => {
            if (typeof window.wap_tms?.custom?.trackGaVirtualPageView === 'function') {
                clearInterval(interval);
                window.wap_tms.custom.trackGaVirtualPageView(pageName);
            } else if (executions >= tries) {
                clearInterval(interval);
            } else {
                executions += 1;
            }
        }, time);
    } catch (e) {
        /* empty */
    }
};

/** @namespace Theme/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    containerProps() {
        return {
            ...super.containerProps(),
            ssoEnabled: sso.isEnabled(),
        };
    }

    initializeApplication() {
        const { init } = this.props;

        if (window.initializedApp) {
            return;
        }

        init();
        window.initializedApp = true;

        window.wapVirtualPageView = trackVirtualPage;

        window.wapVirtualPageView();

        history.listen(() => {
            setTimeout(() => {
                window.wapVirtualPageView();
            }, 1000);
        });
    }

    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;

        if (!this.handleCheckIfOnlyMainItemsRender()) {
            this.setRenderAllItems();
        } else {
            this.setRenderOnlyMainItems();
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                canonical_url,
            } = this.props;

            updateMeta({
                default_title,
                title: meta_title || default_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
                canonical_url,
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
