export const UPDATE_META = 'UPDATE_META';

/** @namespace Theme/Store/Meta/Action/ensureCanonicalUrlDefault */
export const ensureCanonicalUrlDefault = (metadata) => {
    const { canonical_url, ...remainingMetaData } = metadata;
    const { location } = window;

    if (canonical_url) {
        try {
            const urlObject = new URL(canonical_url);

            if (urlObject.pathname !== '/') {
                return metadata;
            }
        } catch (e) {
            return metadata;
        }
    }

    const locationSearchParams = new URLSearchParams(window.location.search);
    const pageParameter = locationSearchParams.get('page');
    const canonicalizedParams = pageParameter ? `?page=${pageParameter}` : '';

    return {
        canonical_url: `${ location.protocol }//${ location.hostname }${ location.pathname }${canonicalizedParams}`,
        ...remainingMetaData,
    };
};

/** @namespace Theme/Store/Meta/Action/updateMeta */
export const updateMeta = (metadata) => ({
    type: UPDATE_META,
    payload: ensureCanonicalUrlDefault(metadata),
});
