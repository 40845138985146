/* eslint-disable react/jsx-no-bind */
import { cloneElement, lazy } from 'react';
import { Route } from 'react-router-dom';

import UrlRewrites from 'Route/UrlRewrites';
import {
    PrintTypes,
} from 'SourceComponent/MyAccountOrderPrint/MyAccountOrderPrint.config';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    OrderPrintPage,
    PasswordChangePage,
    ProductComparePage,
    RouterComponent as SourceRouter,
    SearchPage,
    SendConfirmationPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex,
} from 'SourceComponent/Router/Router.component';
import {
    MyAccountTabs,
} from 'Type/Account.type';

import {
    RouterItemType,
    RouterSwitchItemType,
} from './Router.config';

export const License = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "license" */ 'Route/License'));
export const ShippingTracking = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "shippingTracking" */ 'Route/ShippingTracking'));
export const SitemapPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "sitemap" */ 'Route/Sitemap'));

export {
    CartPage,
    Checkout,
    CmsPage,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    SendConfirmationPage,
    ConfirmAccountPage,
    MenuPage,
    WishlistShared,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    StyleGuidePage,
    OrderPrintPage,

    Header,
    NavigationTabs,
    Footer,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    CookiePopup,
    DemoNotice,
    SomethingWentWrong,
    Breadcrumbs,

    withStoreRegex,
};

/** @namespace Theme/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    [RouterItemType.SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ ({ match }) => <HomePage match={ match } currentUrl={ this.props.currentUrl } /> } />,
            position: 10,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ ({ match }) => <SearchPage match={ match } /> } />,
            position: 25,
            name: RouterSwitchItemType.SEARCH,
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ ({ match }) => <CmsPage match={ match } currentUrl={ this.props.currentUrl } /> } />,
            position: 40,
            name: RouterSwitchItemType.CMS_PAGE,
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ () => <CartPage /> } />,
            position: 50,
            name: RouterSwitchItemType.CART,
        },
        {
            component: <Route path={ withStoreRegex('/quote/:step?') } render={ ({ match }) => <Checkout match={ match } isQuote /> } />,
            position: 53,
            name: RouterSwitchItemType.QUOTE,
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ ({ match }) => <Checkout match={ match } /> } />,
            position: 55,
            name: RouterSwitchItemType.CHECKOUT,
        },
        {
            component: <Route path={ withStoreRegex('/license') } render={ () => <License /> } />,
            position: 56,
            name: RouterSwitchItemType.LICENSE,
        },
        {
            component: <Route path={ withStoreRegex('/shipping/tracking/popup') } render={ () => <ShippingTracking /> } />,
            position: 57,
            name: RouterSwitchItemType.SHIPPING_TRACKING,
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?/:uid?') } render={ () => <LoginAccountPage /> } />,
            position: 59,
            name: RouterSwitchItemType.LOGIN_MY_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ () => <PasswordChangePage /> } />,
            position: 60,
            name: RouterSwitchItemType.CHANGE_PASSWORD,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ () => <CreateAccountPage /> } />,
            position: 61,
            name: RouterSwitchItemType.CREATE_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ () => <LoginAccountPage /> } />,
            position: 62,
            name: RouterSwitchItemType.LOGIN,
        },
        {
            component: <Route path={ withStoreRegex('/login') } render={ () => <LoginAccountPage /> } />,
            position: 63,
            name: RouterSwitchItemType.LOGIN_EXTERNAL,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ () => <ForgotPasswordPage /> } />,
            position: 64,
            name: RouterSwitchItemType.ACCOUNT_FORGOT_PASSWORD,
        },
        {
            component: <Route path={ withStoreRegex('/forgot-password') } render={ () => <ForgotPasswordPage /> } />,
            position: 65,
            name: RouterSwitchItemType.FORGOT_PASSWORD,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ () => <ConfirmAccountPage /> } />,
            position: 67,
            name: RouterSwitchItemType.CONFIRM_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/view/order_id/:orderId?') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_ORDERS } /> } />,
            position: 70,
            name: RouterSwitchItemType.MY_ACCOUNT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/history') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_ORDERS } /> } />,
            position: 71,
            name: RouterSwitchItemType.MY_ACCOUNT_ORDERS,
        },
        {
            component: <Route path={ withStoreRegex('/sales/quote/view/quote_id/:quoteId?') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_QUOTES } /> } />,
            position: 72,
            name: RouterSwitchItemType.MY_ACCOUNT_QUOTE,
        },
        {
            component: <Route path={ withStoreRegex('/sales/quote/history') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_QUOTES } /> } />,
            position: 73,
            name: RouterSwitchItemType.MY_ACCOUNT_QUOTES,
        },
        {
            component: <Route path={ withStoreRegex('/downloadable/customer/products') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_DOWNLOADABLE } /> } />,
            position: 74,
            name: RouterSwitchItemType.MY_ACCOUNT_DOWNLOADABLE,
        },
        {
            component: <Route path={ withStoreRegex('/wishlist') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_WISHLIST } /> } />,
            position: 75,
            name: RouterSwitchItemType.MY_ACCOUNT_WISHLIST,
        },
        {
            component: <Route path={ withStoreRegex('/customer/address') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.ADDRESS_BOOK } /> } />,
            position: 76,
            name: RouterSwitchItemType.MY_ACCOUNT_ADDRESS,
        },
        {
            component: <Route path={ withStoreRegex('/newsletter/manage') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.NEWSLETTER_SUBSCRIPTION } /> } />,
            position: 77,
            name: RouterSwitchItemType.MY_ACCOUNT_NEWSLETTER,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ ({ match }) => <MyAccount match={ match } /> } />,
            position: 78,
            name: RouterSwitchItemType.MY_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ () => <MenuPage /> } />,
            position: 80,
            name: RouterSwitchItemType.MENU,
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ ({ match }) => <WishlistShared match={ match } /> } />,
            position: 81,
            name: RouterSwitchItemType.SHARED_WISHLIST,
        },
        {
            component: <Route path={ withStoreRegex('/contact-us') } render={ () => <ContactPage /> } />,
            position: 82,
            name: RouterSwitchItemType.CONTACT_PAGE,
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ () => <ProductComparePage /> } />,
            position: 83,
            name: RouterSwitchItemType.COMPARE,
        },
        {
            component: <Route path={ withStoreRegex('/sitemap') } render={ () => <SitemapPage /> } />,
            position: 84,
            name: RouterSwitchItemType.SITEMAP,
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ () => <StyleGuidePage /> } />,
            position: 85,
            name: RouterSwitchItemType.STYLE_GUIDE,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/print/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ORDER } /> } />,
            position: 90,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_INVOICES } /> } />,
            position: 91,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_SHIPMENT } /> } />,
            position: 92,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_REFUNDS } /> } />,
            position: 93,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_INVOICE } /> } />,
            position: 94,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_SHIPMENT } /> } />,
            position: 95,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_REFUND } /> } />,
            position: 95,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route render={ ({ match }) => <UrlRewrites match={ match } location={ location } /> } />,
            position: 1000,
            name: RouterSwitchItemType.URL_REWRITES,
        },
    ];

    doNotRenderIfSsoEnabled = [
        RouterSwitchItemType.ACCOUNT_FORGOT_PASSWORD,
        RouterSwitchItemType.CHANGE_PASSWORD,
        RouterSwitchItemType.CONFIRM_ACCOUNT,
        RouterSwitchItemType.CREATE_ACCOUNT,
        RouterSwitchItemType.FORGOT_PASSWORD,
    ];

    renderComponentsOfType(type) {
        const { ssoEnabled } = this.props;

        return this.getSortedItems(type)
            .filter(({ name }) => (!ssoEnabled || (this.doNotRenderIfSsoEnabled.indexOf(name) === -1)))
            .map(({ position, component }) => cloneElement(component, { key: position }));
    }
}

export default RouterComponent;
