/* eslint-disable new-cap */

import {
    CheckoutReducer as SourceCheckoutReducer,
    getInitialState as sourceGetInitialState,
} from 'SourceStore/Checkout/Checkout.reducer';

import {
    RESET_SHIPPING_AND_BILLING,
    UPDATE_IS_SAME_AS_SHIPPING,
    UPDATE_NEW_BILLING_ADDRESS,
    UPDATE_NEW_SHIPPING_ADDRESS,
    UPDATE_SELECTED_BILLING_ADDRESS_ID,
    UPDATE_SELECTED_PAYMENT_METHOD,
    UPDATE_SELECTED_SHIPPING_ADDRESS_ID,
} from './Checkout.action';

/** @namespace Theme/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    selectedShippingAddressId: null,
    selectedBillingAddressId: null,
    isSameAsShipping: null,
    newShippingAddress: {},
    newBillingAddress: {},
    selectedPaymentMethod: '',
});

/** @namespace Theme/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SELECTED_SHIPPING_ADDRESS_ID:
        const { selectedShippingAddressId } = action;

        return {
            ...state,
            selectedShippingAddressId,
        };

    case UPDATE_SELECTED_BILLING_ADDRESS_ID:
        const { selectedBillingAddressId } = action;

        return {
            ...state,
            selectedBillingAddressId,
        };

    case UPDATE_NEW_SHIPPING_ADDRESS:
        const { newShippingAddress } = action;

        return {
            ...state,
            newShippingAddress,
        };

    case UPDATE_IS_SAME_AS_SHIPPING:
        const { isSameAsShipping } = action;

        return {
            ...state,
            isSameAsShipping,
        };

    case UPDATE_NEW_BILLING_ADDRESS:
        const { newBillingAddress } = action;

        return {
            ...state,
            newBillingAddress,
        };

    case UPDATE_SELECTED_PAYMENT_METHOD:
        const { selectedPaymentMethod } = action;

        return {
            ...state,
            selectedPaymentMethod,
        };

    case RESET_SHIPPING_AND_BILLING:
        return {
            ...state,
            shippingFields: {},
            selectedShippingAddressId: null,
            selectedBillingAddressId: null,
            isSameAsShipping: null,
            newShippingAddress: {},
            newBillingAddress: {},
            selectedPaymentMethod: '',
        };

    default:
        return SourceCheckoutReducer(state, action);
    }
};

export default CheckoutReducer;
