import { Fragment } from 'react';

import {
    MetaComponent as SourceMetaComponent,
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Theme/Component/Meta/Component */
export class MetaComponent extends SourceMetaComponent {
    renderMeta() {
        const { metadata } = this.props;

        return [
            <Fragment key="meta-tags">
                { this.renderTitle() }
                { metadata.map((tag) => {
                    const {
                        name,
                        property = null,
                        content,
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
            </Fragment>,
        ];
    }
}

export default MetaComponent;
